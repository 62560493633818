import React from "react"

const PhotoDiver = ({ id, mediaURL }) => (
  <section id={id}>
    <div
      className="image-divider fixed-height"
      style={{
        backgroundImage: `url(${mediaURL})`,
        height: "600px",
      }}
      data-stellar-background-ratio="0.3"
    ></div>
  </section>
)

PhotoDiver.propTypes = {}

PhotoDiver.defaultProps = {}

export default PhotoDiver
